<template>
  <div :style="`font-family:${font},sans-serif`">
    <!-- <div>
      <p>{{ this.rowData.work_gid }}</p>
    </div> -->
    <!-- <div v-for="image in image_view" :key="image">
    {{ image}}
      
    </div> -->
    <!-- <b-img :src="image_view" /> -->

    <div class="row justify-content-center justify-content-around">
      <div class="card col-12 col-md-12 p-1">
        <div>
          <div class="row">
            <div class="col-4">
              <b-button
                variant="danger"
                @click="
                  $router.push({
                    name: 'inputkuruplun',
                  })
                "
              >
                กลับ
              </b-button>
            </div>
            <div class="col-4" style="text-align: center">
              <div class="title head">
                <p class="h2" style="color: #558cef">รายการลงทรัพย์สิน</p>
              </div>
            </div>
            <div class="col-4"></div>
          </div>
          <hr />

          <div class="row justify-content-start">
            <div class="ml-1 h5 text-primary" text="primary">ปีงบประมาณ :</div>
            <div class="ml-1 h5">{{ this.rowData.budget_year }}</div>
            <div class="ml-1 h5 text-primary" text="primary">วันที่รับ :</div>
            <div class="ml-1 h5">
              {{ this.rowData.admitdate }}
            </div>
          </div>
          <div class="row justify-content-start">
            <div class="ml-1 h5 text-primary" text="primary">เลขทะเบียน:</div>
            <div class="ml-1 h5">
              {{ this.rowData.equipment_code }}
            </div>
            <div class="ml-1 h5 text-primary" text="primary">ชื่อทรัพย์สิน:</div>
            <div class="ml-1 h5">{{ this.rowData.e_name }}</div>
            <div class="ml-1 h5 text-primary" text="primary">กลุ่มงาน:</div>
            <div class="ml-1">{{ workname }}</div>
          </div>

          <hr />

          <div class="row justify-content-between">
            <div class="col-12 col-md-6 mt-1">
              <div class="d-flex align-items-center">
                <label class="mr-1 text-primary" text="primary"> จำนวนทรัพย์สิน : </label>
                <div>
                  <b-form-input class="d-inline-block" value="0001" type="number" v-model="keyinput" disabled />
                </div>
                <!-- <div>
                  <b-button
                    class="d-inline-block"
                    variant="primary"
                    @click="generateNumber"
                    >เพิ่ม</b-button
                  >
                </div> -->
                <div v-show="show_b">
                  <b-overlay :show="show_getdata" rounded="sm">
                    <b-button class="d-inline-block ml-1" variant="success" @click.prevent="getdata">บันทึก</b-button>
                  </b-overlay>
                </div>
                <div v-show="!show_b">
                  <b-button class="d-inline-block ml-1" variant="success" v-b-modal.modal-increase
                    >เพิ่มจำนวนทรัพย์สิน</b-button
                  >
                </div>
                <div v-show="!show_b">
                  <b-button class="d-inline-block ml-1" @click="post_data_property" variant="success"
                    >เบิกรายการรับเข้าทั้งหมด</b-button
                  >
                </div>
              </div>
              <b-modal
                id="modal-1"
                title="เบิกรายการรับเข้าทั้งหมด"
                ok-title="ตกลง"
                cancel-title="ยกเลิก"
                no-close-on-backdrop
              >
                <b-card-text>
                  <!-- <div class="ml-1 h5 test-primary">ฝ่ายงาน:</div>
                  <div>
                    <v-select placeholder="เลือกฝ่ายงาน" label="dep_name" v-model="department" :options="departments" />
                  </div> -->
                </b-card-text>
              </b-modal>
              <b-modal
                id="modal-increase"
                title="เพิ่มจำนวนทรัพย์สิน"
                cancel-title="ยกเลิก"
                ok-title="ตกลง"
                @ok="increase_assets_number"
              >
                <b-card-text>
                  <h5>เพิ่มจำนวนทรัพย์สิน</h5>
                  <b-form-input class="d-inline-block" value="0001" type="number" v-model="increase_assets" />
                </b-card-text>
              </b-modal>
            </div>

            <b-form-group class="col-12 col-md-6">
              <div class="d-flex align-items-center">
                <label class="mr-1">ค้นหา</label>
                <b-form-input v-model="searchTerm" placeholder="ค้นหา" type="text" class="d-inline-block mb-1" />
                <div>
                  <!-- <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <b-button variant="outline-primary"
                        ><feather-icon icon="ShareIcon" class="mr-50" />Export
                        <feather-icon icon="ChevronDownIcon" class="mr-50"
                      /></b-button>
                    </template>

                    <b-dropdown-item>
                      <feather-icon icon="FileIcon" class="mr-50" />
                      <span>Excel</span>
                    </b-dropdown-item>
                  </b-dropdown> -->
                </div>
              </div>
            </b-form-group>
          </div>

          <!-- table -->
          <b-overlay :show="show" rounded="sm">
            <vue-good-table
              :columns="columns"
              :rows="rows"
              :pagination-options="{
                enabled: true,
                perPage: pageLength,
              }"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm,
              }"
              :line-numbers="true"
            >
              <template slot="table-row" slot-scope="props">
                <!-- Column: Action -->
                <!-- {{props.row}} -->
                <div v-if="props.column.field === 'serial_number'">
                  <span>
                    <div v-if="props.row.reject_status">
                      <validation-provider #default="{ errors }" rules="required">
                        <b-form-input
                          disabled
                          lazy
                          v-model="props.row.serial_number"
                          @change="
                            changeCell(props.row[props.column.field], props.column.field, props.row.originalIndex)
                          "
                          :state="errors.length > 0 ? false : null"
                        />
                      </validation-provider>
                    </div>
                    <div v-else>
                      <validation-provider #default="{ errors }" rules="required">
                        <b-form-input
                          lazy
                          v-model="props.row.serial_number"
                          @change="
                            changeCell(props.row[props.column.field], props.column.field, props.row.originalIndex)
                          "
                          :state="errors.length > 0 ? false : null"
                        />
                      </validation-provider>
                    </div>

                    <!-- <b-input-group size="sm">
                      <b-form-input :list="`mealDish${props.index }`" :id="`input${props.index }`" placeholder="Selectionner un plat" v-model="props.row.serial_number" />
                      <datalist :id="`mealDish${props.index }`">
                        <option v-for="props in props.index" :data-value="props.index">{{}}</option>
                      </datalist>

                      <b-input-group-append>
                        <b-button variant="primary" @click="onClick" :disabled="loading">
                          <i :class="loading ? 'fa fa-spin fa-circle-notch' : 'fa fa-plus'" />
                        </b-button>
                      </b-input-group-append>
                    </b-input-group> -->
                  </span>
                </div>

                <div v-else-if="props.column.field === 'place'">
                  <span>
                    <div v-if="props.row.reject_status">
                      <validation-provider #default="{ errors }" rules="required">
                        <b-form-input
                          disabled
                          lazy
                          v-model="props.row.place"
                          @change="
                            changeCell(props.row[props.column.field], props.column.field, props.row.originalIndex)
                          "
                          :state="errors.length > 0 ? false : null"
                        />
                      </validation-provider>
                    </div>
                    <div v-else>
                      <validation-provider #default="{ errors }" rules="required">
                        <b-form-input
                          lazy
                          v-model="props.row.place"
                          @change="
                            changeCell(props.row[props.column.field], props.column.field, props.row.originalIndex)
                          "
                          :state="errors.length > 0 ? false : null"
                        />
                      </validation-provider>
                    </div>
                  </span>
                </div>

                <div v-else-if="props.column.field === 'e_status'">
                  <div v-if="props.row.reject_status">
                    <span>
                      <b-form-checkbox
                        lazy
                        v-model="props.row.e_status"
                        name="some-radios"
                        value="true"
                        @change="changeCell(props.row[props.column.field], props.column.field, props.row.originalIndex)"
                      >
                        <span>
                          <div v-if="props.row.e_status === 'true'">
                            <b-button disabled variant="success" @click="props.row.e_status = 'false'">
                              ยืมได้
                            </b-button>
                          </div>
                          <div v-else-if="props.row.e_status === null || 'false'">
                            <b-button disabled variant="danger" @click="props.row.e_status = 'true'">
                              ยืมไม่ได้
                            </b-button>
                          </div>
                        </span>
                      </b-form-checkbox>
                    </span>
                  </div>
                  <div v-else>
                    <span>
                      <b-form-checkbox
                        lazy
                        v-model="props.row.e_status"
                        name="some-radios"
                        value="true"
                        @change="changeCell(props.row[props.column.field], props.column.field, props.row.originalIndex)"
                      >
                        <span>
                          <div v-if="props.row.e_status === 'true'">
                            <b-button variant="success" @click="props.row.e_status = 'false'"> ยืมได้ </b-button>
                          </div>
                          <div v-else-if="props.row.e_status === null || 'false'">
                            <b-button variant="danger" @click="props.row.e_status = 'true'"> ยืมไม่ได้ </b-button>
                          </div>
                        </span>
                      </b-form-checkbox>
                    </span>
                  </div>
                </div>
                <div v-else-if="props.column.field === 'responsename'">
                  <div v-if="props.row.reject_status">
                    <span>
                      <validation-provider #default="{ errors }" rules="required">
                        <b-form-input
                          disabled
                          lazy
                          v-model="props.row.responsename"
                          @change="
                            changeCell(props.row[props.column.field], props.column.field, props.row.originalIndex)
                          "
                          :state="errors.length > 0 ? false : null"
                        />
                      </validation-provider>
                    </span>
                  </div>
                  <div v-else>
                    <span>
                      <validation-provider #default="{ errors }" rules="required">
                        <b-form-input
                          lazy
                          v-model="props.row.responsename"
                          @change="
                            changeCell(props.row[props.column.field], props.column.field, props.row.originalIndex)
                          "
                          :state="errors.length > 0 ? false : null"
                        />
                      </validation-provider>
                    </span>
                  </div>
                </div>

                <span v-else-if="props.column.field === 'edit'" v-show="!show_b">
                  <div v-if="props.row.reject_status">
                    <b-button disabled variant="outline-warning" v-b-modal="`modal-edit${props.index}`">
                      <feather-icon icon="EditIcon" class="mr-50" />
                    </b-button>
                  </div>
                  <div v-else>
                    <b-button
                      variant="outline-warning"
                      v-b-modal="`modal-edit${props.index}`"
                      @change="ccdata()"
                      @click="edit_data_rows(props.row)"
                    >
                      <feather-icon icon="EditIcon" class="mr-50" />
                    </b-button>
                  </div>

                  <b-modal
                    no-close-on-backdrop
                    :id="`modal-edit${props.index}`"
                    :title="`แก้ไขรายการลงทรัพย์สิน ${props.row.e_number}`"
                    ok-title="ตกลง"
                    cancel-title="ยกเลิก"
                    @ok="edit_data(props.row)"
                  >
                    <b-card-text class="text-center">
                      <b-form-group label-cols="3" label-cols-lg="2" label=" Serial Number:">
                        <template v-slot:label> Serial Number <span class="text-danger"> </span> </template>
                        <b-form-input v-model="props.row.serial_number" />
                      </b-form-group>
                      <b-form-group label-cols="3" label-cols-lg="2" label="สถานที่เก็บ:">
                        <template v-slot:label> สถานที่เก็บ <span class="text-danger"> </span> </template>
                        <b-form-input v-model="props.row.place" />
                      </b-form-group>
                      <b-form-group label-cols="3" label-cols-lg="2" label="ผู้รับผิดชอบ:">
                        <template v-slot:label> ผู้รับผิดชอบ <span class="text-danger"> </span> </template>
                        <b-form-input v-model="props.row.responsename" />
                      </b-form-group>
                      <b-form-group label-cols="3" label-cols-lg="2" label="ผู้รับผิดชอบ:">
                        <template v-slot:label> กลุ่มงาน <span class="text-danger"> </span> </template>
                        <v-select
                          label="work_gid_work_gname"
                          v-model="workG"
                          :options="workGs"
                          placeholder="เลือกกลุ่มงาน"
                          @input="work_select($event)"
                        />
                      </b-form-group>
                      <b-form-group label-cols="3" label-cols-lg="2" label="ผู้รับผิดชอบ:">
                        <template v-slot:label> ผู้เบิก <span class="text-danger"> </span> </template>
                        <b-form-input v-model="props.row.withdraw_person" />
                      </b-form-group>
                      <b-form-group label-cols="3" label-cols-lg="2" label="ยืมได้:">
                        <template v-slot:label> ยืมได้ <span class="text-danger"> </span> </template>
                        <b-form-checkbox v-model="props.row.e_status" name="some-radios" value="true">
                        </b-form-checkbox>
                      </b-form-group>
                      <div>
                        <span class="text-danger">กด ตกลง เพื่อยืนยันข้อมูล</span>
                      </div>
                    </b-card-text>
                  </b-modal>
                </span>
                <span v-else-if="props.column.field === 'image'" v-show="!show_b">
                  <div v-if="props.row.reject_status">
                    <b-button
                      disabled
                      variant="outline-warning"
                      v-b-modal="`modal-image${props.index}`"
                      @click="get_image(props.row.e_number)"
                    >
                      <feather-icon icon="ImageIcon" class="mr-50" />
                    </b-button>
                  </div>
                  <div v-else>
                    <!-- edit -->
                    <b-button
                      variant="outline-warning"
                      v-b-modal="`modal-image${props.index}`"
                      @click="get_image_edit(props.row.e_number)"
                    >
                      <feather-icon icon="ImageIcon" class="mr-50" />
                    </b-button>
                  </div>

                  <b-modal
                    no-close-on-backdrop
                    :id="`modal-image${props.index}`"
                    title="เพิ่มรูปภาพรายการลงทรัพย์สิน"
                    cancel-title="ยกเลิก"
                    ok-title="ตกลง"
                    @ok="post_image(props.row)"
                  >
                    <b-card-text class="text-center">
                      <div v-for="(image_edis, index) in image_edit" :key="index">
                        <b-img width="100%" :src="`data:image/png;base64,${image_edis}`" alt="" />

                        <b-button @click="edit_image_del(image_edis, index)" variant="danger" size="sm">
                          <feather-icon icon="XIcon"
                        /></b-button>
                      </div>

                      <!-- <div>
                        <div v-if="image_p != null">
                          <div v-for="(image_p, index) in image_p" :key="image_p">
                            <div class="delect_icon" @click="delect_p(index)">
                              <b-icon icon="x-circle" scale="2" variant="danger"></b-icon>
                            </div>
                            <a>
                              <b-img
                                width="250%"
                                height="250%"
                                src
                                :onerror="`fetch('${API}files/image/${image_p}',
                      {headers: {authorization:'${token}'}})
                      .then(r=>r.blob()).then(d=>
                      this.src=window.URL.createObjectURL(d))`"
                                class="mx-1 delect_img mt-3"
                            /></a>
                          </div>
                        </div>
                      </div> -->
                      <b-form-file
                        @input="update_image_file(file_image)"
                        v-model="file_image"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                        multiple
                        accept="image/*"
                      />
                      <!-- <img
                        src
                        :onerror="`fetch('${API}files/image/${props.row.m_image[0]}',
                      {headers: {authorization:'${token}'}})
                      .then(r=>r.blob()).then(d=>
                      this.src=window.URL.createObjectURL(d))`"
                      /> -->
                    </b-card-text>
                  </b-modal>
                </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
                  <span>
                    <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                      <template v-slot:button-content>
                        <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
                      </template>
                      <b-dropdown-item v-b-modal="`modal-1${props.index}`">
                        <feather-icon icon="GridIcon" class="mr-50" />
                        <span>คิวอาร์โค้ด</span>
                      </b-dropdown-item>
                      <!-- <b-dropdown-item v-b-modal="`modal-2${props.index}`">
                        <feather-icon icon="ImageIcon" class="mr-50" />
                        <span>เพิ่มรูปภาพ</span>
                      </b-dropdown-item> -->
                      <!-- <b-dropdown-item>
                        <feather-icon icon="TrashIcon" class="mr-50" />
                        <span>ลบ</span>
                      </b-dropdown-item> -->
                    </b-dropdown>
                  </span>
                  <b-modal ok-only ok-title="ตกลง" title="Qrcode" :id="`modal-1${props.index}`" class="text-nowrap">
                    <div class="text-center">
                      <br />
                      <span class="text-nowrap">หมายเลขทรัพย์สิน: {{ props.row.e_number }}</span>
                    </div>
                  </b-modal>
                  <b-modal :id="`modal-2${props.index}`" title="เพิ่มรูปภาพ" ok-only ok-title="ตกลง">
                    <b-card-text>
                      <!-- {{ props.row }} -->
                      <div class="text-center">
                        <b-img thumbnail fluid :src="`${props.row.e_image}`" />
                      </div>

                      <b-form-file
                        @change="onFileChange"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                      />
                      <span class="text-nowrap">หมายเลขทรัพย์สิน: {{ props.row.e_number }}</span>
                    </b-card-text>
                  </b-modal>
                </span>

                <span v-else-if="props.column.field === 'รูปภาพ'" v-show="!show_b">
                  <b-button
                    variant="outline-warning"
                    v-b-modal="`modal-3${props.index}`"
                    @click="get_image(props.row.e_number)"
                  >
                    <feather-icon icon="ImageIcon" />
                  </b-button>
                  <!-- <img :src="[props.row.qrcode_img]" alt="" /> -->
                  <b-modal
                    no-close-on-backdrop
                    :id="`modal-3${props.index}`"
                    title="รูปภาพ"
                    ok-only
                    ok-title="ตกลง"
                    @ok="cc_data(props.row)"
                    size="md"
                  >
                    <b-card-text class="text-center">
                      <div v-if="image_p != null && image_p != ''">
                        <swiper
                          ref="mySwiper"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="swiperOptions"
                        >
                          <swiper-slide v-for="image_p in image_p" :key="image_p">
                            <b-img
                              center
                              fluid
                              alt="Responsive image"
                              src
                              :onerror="`fetch('${API}files/image/${image_p}',
                      {headers: {authorization:'${token}'}})
                      .then(r=>r.blob()).then(d=>
                      this.src=window.URL.createObjectURL(d))`"
                              class="mx-1"
                            />
                          </swiper-slide>
                          <div slot="button-next" class="swiper-button-next" />
                          <div slot="button-prev" class="swiper-button-prev" />
                          <div slot="pagination" class="swiper-pagination" />
                        </swiper>
                      </div>
                      <span v-else>
                        <div class="text-center">ไม่มีรูปภาพ</div>
                      </span>
                      <span class="text-nowrap"></span
                    ></b-card-text>
                  </b-modal>
                </span>

                <div v-else-if="props.column.field === 'record'">
                  <span>
                    <b-button
                      variant="outline-warning"
                      v-b-modal="`modal-record${props.index}`"
                      @click="get_log(props.row)"
                    >
                      <feather-icon icon="LayersIcon" />
                    </b-button>
                    <b-modal :id="`modal-record${props.index}`" title="ประวัติ" ok-only ok-title="ปิด" size="xl">
                      <b-card-text>
                        <div>
                          <!-- search input -->
                          <div class="custom-search d-flex justify-content-end">
                            <b-form-group>
                              <div class="d-flex align-items-center">
                                <label class="mr-1">Search</label>
                                <b-form-input
                                  v-model="searchTerm_log"
                                  placeholder="Search"
                                  type="text"
                                  class="d-inline-block"
                                />
                              </div>
                            </b-form-group>
                          </div>

                          <!-- table -->
                          <vue-good-table
                            :columns="columns_log"
                            :rows="rows_log"
                            :search-options="{
                              enabled: true,
                              externalQuery: searchTerm_log,
                            }"
                            :pagination-options="{
                              enabled: true,
                              perPage: pageLength_log,
                            }"
                          >
                            {{ rows_log }}
                            <!-- pagination -->
                            <template slot="pagination-bottom" slot-scope="props">
                              <div class="d-flex justify-content-between flex-wrap">
                                <div class="d-flex align-items-center mb-0 mt-1">
                                  <span class="text-nowrap"> Showing 1 to </span>
                                  <b-form-select
                                    v-model="pageLength_log"
                                    :options="['5', '10', '20']"
                                    class="mx-1"
                                    @input="
                                      (value) =>
                                        props.perPageChanged({
                                          currentPerPage: value,
                                        })
                                    "
                                  />
                                  <span class="text-nowrap"> of {{ total_log }} entries </span>
                                </div>
                                <div>
                                  <b-pagination
                                    :value="1"
                                    :total-rows="total_log"
                                    :per-page="pageLength"
                                    first-number
                                    last-number
                                    align="right"
                                    prev-class="prev-item"
                                    next-class="next-item"
                                    class="mt-1 mb-0"
                                    @input="
                                      (value) =>
                                        props.pageChanged({
                                          currentPage: value,
                                        })
                                    "
                                  >
                                    <template #prev-text>
                                      <feather-icon icon="ChevronLeftIcon" size="18" />
                                    </template>
                                    <template #next-text>
                                      <feather-icon icon="ChevronRightIcon" size="18" />
                                    </template>
                                  </b-pagination>
                                </div>
                              </div>
                            </template>
                          </vue-good-table>
                        </div>
                      </b-card-text>
                    </b-modal>
                  </span>
                </div>
                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <!-- <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Showing 1 to </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['5', '10', '15', '25', '50', '100']"
                      class="mx-1"
                      @input="(value) => perPageChanged({ currentPerPage: value })"
                    />
                    <span class="text-nowrap">of {{ total }} entries</span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="(value) => pageChanged({ currentPage: value })"
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template> -->
            </vue-good-table>
          </b-overlay>
        </div>
        <!-- insert -->
        <vue-good-table
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
          :line-numbers="true"
        >
          <template slot="table-row" slot-scope="props">
            <!-- Column: Action -->
            <!-- {{props.row}} -->
            <div v-if="props.column.field === 'serial_number'">
              <span>
                <b-form-input
                  v-model="props.row.serial_number"
                  @change="changeCell(props.row[props.column.field], props.column.field, props.row.originalIndex)"
                />
              </span>
            </div>

            <div v-else-if="props.column.field === 'place'">
              <span>
                <b-form-input
                  v-model="props.row.place"
                  @change="changeCell(props.row[props.column.field], props.column.field, props.row.originalIndex)"
                />
              </span>
            </div>
            <div v-else-if="props.column.field === 'e_status'">
              <span>
                <b-form-checkbox
                  v-model="props.row.e_status"
                  name="some-radios"
                  value="true"
                  @change="changeCell(props.row[props.column.field], props.column.field, props.row.originalIndex)"
                >
                  <span>
                    <div v-if="props.row.e_status === 'true'">
                      <b-button variant="success"> ยืมได้ </b-button>
                    </div>
                    <div v-else-if="props.row.e_status === null || 'false'">
                      <b-button variant="danger"> ยืมไม่ได้ </b-button>
                    </div>
                  </span>
                </b-form-checkbox>
              </span>
            </div>
            <div v-else-if="props.column.field === 'responsename'">
              <span>
                <b-form-input
                  v-model="props.row.responsename"
                  @change="changeCell(props.row[props.column.field], props.column.field, props.row.originalIndex)"
                >
                </b-form-input>
              </span>
            </div>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Showing 1 to </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['5', '10', '15', '50']"
                  class="mx-1"
                  @input="(value) => props.perPageChanged({ currentPerPage: value })"
                />
                <span class="text-nowrap">of {{ props.total }} entries</span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
      <!-- <div class="card col-12 col-md-2"></div> -->
    </div>
    <iframe id="my_iframe" style="display: none"></iframe>
  </div>
</template>

<script>
import { BTabs, BTab, BCarousel, BCarouselSlide } from "bootstrap-vue";
import { BFormInput, BRow, BCol, BFormGroup } from "bootstrap-vue";
import { BTable, BFormRadio, BFormCheckbox, BFormRadioGroup, BCardBody } from "bootstrap-vue";
import vSelect from "vue-select";
import { BButton } from "bootstrap-vue";
import { BFormFile } from "bootstrap-vue";
import { BFormSelect } from "bootstrap-vue";
import { BFormDatepicker } from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import axios from "axios";
import API from "@/views/connectDB/condb.js";

import { BImg } from "bootstrap-vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
// import fs from 'fs-extra';
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { required } from "@validations";
import _ from "lodash";

import FormData from "form-data";
import { async } from "q";
import { is } from "vee-validate/dist/rules";
export default {
  props: {
    rowData: Object,
  },
  components: {
    VueGoodTable,
    BFormDatepicker,
    BFormSelect,
    BFormFile,
    BRow,
    BCol,
    BButton,
    BTable,
    BCardBody,
    vSelect,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BTabs,
    BTab,

    BImg,
    Swiper,
    SwiperSlide,
    BCarousel,
    BCarouselSlide,
    ValidationObserver,
    ValidationProvider,
    required,
  },
  data() {
    return {
      swiperOptions: {
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        pagination: {
          el: ".swiper-pagination",
          type: "progressbar",
        },
      },
      dele_image: [],
      dele_imageFinal: [],
      add_name_image: [],
      increase_assets: "",
      columns_log: [
        {
          label: "Username",
          field: "username",
        },
        {
          label: "หมายเลขทรัพย์สิน",
          field: "e_number",
        },
        {
          label: "สถานที่เก็บ",
          field: "place",
        },
        {
          label: "ผู้รับผิดฃอบ",
          field: "responsename",
        },
        {
          label: "กลุ่มงาน",
          field: "work_gname",
        },
        {
          label: "วันที่",
          field: "createdAt",
        },
        {
          label: "เวลา",
          field: "time",
        },
      ],
      total: "",
      month: [
        "",
        "มกราคม",
        "กุมภาพันธ์ ",
        "มีนาคม ",
        "เมษายน ",
        "พฤษภาคม ",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
      ],
      show_getdata: false,
      workG: "",
      workGs: [],
      month_en: ["", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
      rows_log: [],
      searchTerm_log: "",
      responsible_person: "",
      lend: null,
      image_view: "",
      token: null,
      file_image: [],
      API: `${API}`,
      file_mImg: [],
      qrcode_img: [],
      show: false,
      keyinput: this.rowData.amount,
      pageLength: 100,
      currentPage: 1,
      pageLength_log: 5,
      dir: false,
      selected: null,
      number: "0001",
      show_b: true,
      Serial: "",
      proofs: [],
      department: "",
      departments: [],
      req_name_: "",
      req_name: "",
      getlog: "",
      options: [
        { value: null, text: "เลือกประเภทหลักฐาน" },
        { value: "a", text: "ใบส่งของ/ใบกำกับาษี" },
      ],
      columns: [
        {
          label: "หมายเลขทรัพย์สิน",
          field: "e_number",
        },
        {
          label: "Serial Number",
          field: "serial_number",
        },
        {
          label: "รายละเอียด",
          field: "information",
        },

        {
          label: "หน่วยนับ",
          field: "unittype",
        },
        {
          label: "ราคาต่อหน่วย",
          field: "unitprice",
          type: "number",
          sortable: true,
          formatFn: this.formatFn,
        },
        {
          label: "ราคาต่อหน่วย + vat 7%",
          field: "unitprice_vat",
          type: "number",
          sortable: true,
          formatFn: this.formatFn,
        },

        {
          label: "สถานที่เก็บ",
          field: "place",
        },
        {
          label: "ผู้รับผิดฃอบ",
          field: "responsename",
        },
        {
          label: "ยืมได้/ยืมไม่ได้",
          field: "e_status",
        },
        {
          label: "แก้ไข",
          field: "edit",
        },
        {
          label: "เพิ่ม/แก้ไข รูปภาพ",
          field: "image",
        },

        // {
        //   label: 'คิวอาร์โค้ด',
        //   field: 'action',
        // },
        {
          label: "ดูรูปภาพ",
          field: "รูปภาพ",
        },
        {
          label: "ประวัติ",
          field: "record",
        },
      ],
      rows: [],
      temp_rows: [],
      searchTerm: "",
      image_p: "",
      ycode: this.rowData.budget_year.substring(2, 4),
      workname: "",
      equipment_code: "",
      image_view: [],
      preview: "",
      base64: null,
      total_log: "",
      image_edit: [],
      image_update: [],
      image_update_upload: [],
      font: "",
    };
  },
  computed: {
    fileImg() {
      return _.isEmpty(this.image_p) ? console.log(this.image_p) : console.log(this.image_p);
    },
    example: {
      cache: false,
      get: function () {
        return Date.now() + this.msg;
      },
    },
  },
  async created() {
    const font = localStorage.getItem("font");
    console.log(font);
    this.font = font;
    _.isEmpty(this.rowData) ? this.$router.push({ path: "/receive-equipment" }) : true;
    // console.log(this.ycode);
    const { access_token } = await this.access_token();
    this.getdataTable();
    this.workgroups();
    this.s_evidenceTypes();
    this.material_code();
    this.token = await access_token;
    this.work_select();
    this.work();
    this.auto_();
  },
  methods: {
    async edit_data_rows(value) {
      this.workG = "";
      console.log(value);
      // console.log(value.e_number);
      const { access_token } = await this.access_token();
      const url = `${API}equipmentLogs?e_number=${value.e_number}`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      console.log(res.data.message.data.length);

      const workG = res.data.message.data.map((el, index) => {
        console.log(el);
        if (el.length) {
        }
        return {
          ...el,
          work_gid_work_gname: `${el.work_gid} - ${el.work_gname}`,
        };
      });
      this.workG = workG[0];
      console.log(this.workG);
    },
    ccdata() {
      this.workG = "";
    },
    update_image_file(value) {
      console.log(value);
      console.log("aaa");
      console.log(this.file_image);
      console.log(this.dele_image);
      this.image_update_upload.push(...value);
      console.log(this.image_update_upload);
    },
    async edit_image_del(value, indexs) {
      const image_editFilter = [];
      this.image_update_upload.map((res, index) => {
        if (res.name.split(".png")[0] !== this.dele_image[indexs]) {
          image_editFilter.push(this.image_edit[index]);
        }
      });
      this.image_edit = image_editFilter;
      console.log(this.image_edit);
      const datas = this.image_update_upload.filter((res) => res.name.split(".png")[0] !== this.dele_image[indexs]);
      console.log(datas);
      this.image_update_upload = datas;
      this.dele_imageFinal.push(this.dele_image[indexs]);
      const arrayImageName = this.dele_image.filter((item) => item !== this.dele_image[indexs]);
      this.dele_image = arrayImageName;
    },
    async get_image_edit(image) {
      this.add_name_image = [];
      this.dele_imageFinal = [];
      this.image_update_upload = [];
      this.dele_image = [];
      console.log(image);
      this.image_edit = [];
      const { access_token } = await this.access_token();
      const url = `${API}equipmentDetail?e_number=${image.replace("/", "_")}`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      console.log(res);
      const a = res.data.message.data[0].e_image;
      const b = JSON.parse(a);
      console.log(b);
      const image_p = b;
      // this.dele_image = b;

      image_p.forEach(async (element, index) => {
        // this.dele_image.push(element);
        const url_i = `${API}files/image/${element}`;
        const head_i = {
          responseType: "arraybuffer",
          headers: {
            Authorization: await access_token,
          },
        };
        const res_i = await axios.get(url_i, head_i);

        var arrayBuffer = res_i.data;
        var u8 = new Uint8Array(arrayBuffer);
        var b64encoded = btoa(String.fromCharCode.apply(null, u8));
        // console.log(b64encoded);
        this.dele_image.push(element);
        console.log(element);
        this.image_edit.push(b64encoded);
        const trimmedString = await b64encoded;
        const imageContent = atob(trimmedString);
        const buffer = new ArrayBuffer(imageContent.length);
        const view = new Uint8Array(buffer);
        for (let n = 0; n < imageContent.length; n++) {
          view[n] = imageContent.charCodeAt(n);
        }
        const type = "image/png";
        const blob = new Blob([buffer], { type });
        // this.image_update_upload = blob;
        var fileToUpload = new File([blob], `${element}.png`, {
          type,
          lastModified: new Date(),
        });

        this.image_update_upload.push(fileToUpload);
        console.log(this.image_update_upload);
      });

      console.log(this.image_edit);
      // /this.image_file();
    },

    // perPageChanged(value) {
    //   console.log(value.currentPerPage);
    //   this.pageLength = value.currentPerPage;
    //   this.getdataTable();
    // },
    // pageChanged(value) {
    //   console.log(value.currentPage);
    //   this.currentPage = value.currentPage;
    //   this.getdataTable();
    // },
    search: (e) => console.log(e),
    async work() {
      const { access_token } = await this.access_token();
      const workgroups = `${API}workgroups?_page=1&_limit=100&_sort=1`;
      const header_workgroups = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res_workgroups = await axios.get(workgroups, header_workgroups);
      const res_amp = res_workgroups.data.message.data.map((element) => {
        return {
          ...element,
          work_gid_work_gname: `${element.work_gid} - ${element.work_gname}`,
        };
      });
      this.workGs = res_amp;
    },
    // async cclog(files) {
    //   console.log(files);
    //   let img_array = [];
    //   let img_st = '';

    //   files.forEach((element, index) => {
    //     console.log(element);
    //     const reader = new FileReader();

    //     let rawImg;
    //     reader.onloadend = () => {
    //       rawImg = reader.result;
    //       // this.preview = rawImg;

    //       //   img_array.push(rawImg);
    //       this.image_view = rawImg;

    //       console.log(this.image_view);
    //     };
    //     reader.readAsDataURL(element);
    //   });
    // },
    async get_image(image) {
      // console.log(image);
      this.image_p = "";
      const { access_token } = await this.access_token();
      this.token = await access_token;
      const url = `${API}equipmentDetail?e_number=${image.replace("/", "_")}`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };

      const res = await axios.get(url, head);
      console.log(res);
      if (res.data.message.data[0].e_image) {
        this.image_p = res.data.message.data[0].e_image;
        this.image_p = JSON.parse(this.image_p);
        this.image_p = this.image_p;
        console.log(this.image_p);
        const arr = [];
        this.image_p.forEach(async (element) => {
          console.log(element);

          const reader = new FileReader();

          fetch(`${API}files/image/${element}`, {
            responseType: "arraybuffer",
            headers: { authorization: `${this.token}` },
          })
            .then((r) => r.blob())
            .then(async (d) => {
              console.log(d);
              console.log(element);
              const type = "image/webp";

              let file = new File([d], `${element}`, { type: type });
              console.log(file);

              // const url = window.URL.createObjectURL(new Blob([file]));
              // const blob = new Blob([d], { type: type });
              // const link = document.createElement('a');
              // link.href = URL.createObjectURL(blob);
              // link.download =  `${element}.jpg`;
              // link.click();
              // URL.revokeObjectURL(link.href);

              reader.readAsDataURL(await d);
              reader.onload = function () {
                //console.log(reader.result);
              };
            });
        });
        for (let index = 0; index < this.image_p.length; index++) {
          return;
        }

        console.log(arr);
        this.image_view = URL.createObjectURL(file);
        console.log(this.image_view);
      } else {
        this.image_p = null;
      }
    },
    async post_image(value) {
      let { access_token } = await this.access_token();
      console.log(this.dele_imageFinal);

      this.dele_imageFinal.forEach(async (el) => {
        console.log(el);
        const dele = `${API}equipmentDetailImage/${value.e_number.replace("/", "_")}`;
        // console.log(dele);
        // const data_image = {
        //   img_name: el,
        // };
        // console.log(data_image);
        // const head_image = {
        //   headers: {
        //     Authorization: await access_token,
        //   },
        // };
        // const res_image = await axios.delete(dele, data_image, head_image);
        // console.log(res_image);

        const res_image = await axios.delete(dele, {
          headers: {
            Authorization: await access_token,
          },
          data: {
            img_name: el,
          },
        });
        console.log(res_image);
      });

      console.log(this.image_update_upload);
      // if (this.image_update_upload.length != 0) {
      //   this.file_image = this.image_update_upload;
      // }
      console.log(this.file_image);
      console.log(value);

      // return

      this.file_image = [];
      this.file_image = this.image_update_upload;

      console.log(this.file_image);

      const url = `${API}equipmentDetail/${value.e_number.replace("/", "_")}`;
      const data = new FormData();

      const aa = this.image_view.length;
      console.log(this.file_image);
      if (this.file_image != null) {
        for (let index = 0; index < this.file_image.length; index++) {
          this.image_view[aa] = this.file_image[index];
        }
      }
      if (this.file_image.length == 0) {
        console.log("ttttttttttttttttttttttttttttttttttttttttttttttt");
        data.append("e_image", []);
      }

      this.file_image.forEach((element) => {
        console.log(element);
        data.append("e_image", element);
      });
      for (value in data) {
        console.log(value);
      }
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };

      const res = await axios.put(url, data, head);
      console.log(res);
      this.$emit("show");
      // $(this).data('modal').$element.removeData();
      // location.reload();
    },

    delect_p(value) {
      // console.log(value);
      // console.log(this.image_p);
      // this.image_p.push(delete this.image_p[value]);
      //console.log(this.image_view);
      this.image_p.splice(value, 1);
      this.image_view.splice(value, 1);
      // console.log(this.image_p);
      // console.log(this.image_view);
    },
    async work_select(event) {
      const { access_token } = await this.access_token();
      const m_gid = this.rowData.work_gid;
      // console.log(this.rowData.e_gid);
      const url = `${API}departments?work_gid=${m_gid}`;
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, header);
      // console.log(res.data.message.data);
      const res_amp = res.data.message.data.map((element) => {
        return {
          ...element,
          dep_name: `${element.dep_id} - ${element.dep_name}`,
        };
      });
      this.departments = res_amp;
    },
    async auto_() {
      console.log(this.department);
      const { work_gid, fullname } = await JSON.parse(localStorage.getItem("username_type"));

      const { access_token } = await this.access_token();
      const url_herd = `${API}headWorkgroup?work_gid=${work_gid}`;
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res_heard = await axios.get(url_herd, header);
      console.log(res_heard);
      console.log(res_heard.data.message.data[0].firstname);

      const url = `${API}departments?work_gid=${this.rowData.work_gid}`;

      const res = await axios.get(url, header);
      console.log(res.data.message.data[0].work_gid);
      console.log(res.data.message.data[0].dep_id);
      this.department = res.data.message.data[0].dep_id;
      console.log(this.department);
      this.req_name_ = fullname;
      this.req_name = `${res_heard.data.message.data[0].firstname} ${res_heard.data.message.data[0].lastname} `;
    },
    async post_data_property() {
      console.log(this.department);
      const { access_token } = await this.access_token();
      const head_checkTaken = {
        headers: {
          Authorization: await access_token,
        },
      };
      const checkTaken_url = `${API}checkTakenEquipment`;
      const checkTaken_data = {
        data: JSON.stringify(this.rows),
        task: "เบิก",
      };
      const res_checkTaken = await axios.post(checkTaken_url, checkTaken_data, head_checkTaken);

      console.log(res_checkTaken);
      if (res_checkTaken.data.status === 400) {
        console.log(res_checkTaken);
        Swal.fire({
          position: "center",
          icon: "error",
          text: `${res_checkTaken.data.message}`,
          title: "ทรัพย์สินมีการเบิกเเล้ว",
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      }

      let head = {
        headers: {
          Authorization: await access_token,
        },
      };
      // console.log(this.rowData);
      // console.log(this.rows);
      // console.log(this.equipment_code);
      let data = {
        equipment_code: "",
        req_date: this.rowData.date_raw,
        budget_year: this.rowData.budget_year,
        important_number: this.rowData.important_number == null ? " " : this.rowData.important_number,
        evidence_type: this.rowData.evidence_type == "undefined" ? " " : this.rowData.evidence_type,
        action_date: this.rowData.date_raw,
        work_gid: this.rowData.work_gid,
        dep_id: this.department,

        need_date: this.rowData.date_raw,
        staff1_position: " ",
        staff3_position: " ",

        //หัวหน้า
        req_name: this.req_name,

        //ผู้เบิก
        requistion_name: this.rowData.response_person,
        equipment_code_gen: "true",
        status_withdraw: "รอเบิก",
      };
      console.log(data);

      let property = [];
      this.rows.filter((element_a, index) => {
        if (element_a.e_status == "false") {
          property.push(element_a);
        }
      });
      // console.log(property);
      property.filter(async (element, index) => {
        const url = `${API}checkExistEquiptmentRequisition?e_number=${element.e_number}`;
        const res_amp = await axios.get(url, head);
        // console.log(res_amp);
        if (res_amp.data.message.total != 0) {
          property = [];
          data = {};
          Swal.fire({
            position: "center",
            icon: "error",
            title: `ทรัพย์สินในรายการมีทะเบียนเบิก`,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          console.log(property, data);
          const url_a = `${API}withdraw`;
          const res_dataA = await axios.post(url_a, data, head);
          console.log(res_dataA);
          const url_b = `${API}withdrawList`;
          console.log(url_b);
          if (res_dataA.data.status === 200) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "บันทึก",
              showConfirmButton: false,
              timer: 1500,
            });
            this.getlog = res_dataA.data.message.equipment_code;
            const res_dataB = await Promise.all(
              await axios.post(
                url_b,
                {
                  equipment_code: res_dataA.data.message.equipment_code,
                  work_gid: this.rowData.work_gid,
                  e_id: this.rowData.e_id,
                  e_gid: this.rowData.e_gid,
                  e_number: element.e_number,
                  serial_number: element.serial_number,
                },
                head
              )
            );
            console.log(res_dataB);
          }
        }
      });
    },
    async s_evidenceTypes() {
      const { access_token } = await this.access_token();
      const url = `${API}evidenceTypes`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      this.proofs = res.data.message.data;
    },
    async material_code() {
      const { access_token } = await this.access_token();
      const url = `${API}withdraw`;
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      const data_res = await axios.get(url, header);
      const { equipment_code, code_number, budget_year } = data_res.data.message.data[0];
      const materialcode = `${equipment_code}-${code_number.toString().padStart(3, "0")}-${budget_year}`;
      this.equipment_code = materialcode;
    },
    async workgroups() {
      const { access_token } = await this.access_token();
      const url = `${API}workgroups?work_gid=${this.rowData.work_gid}`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      const { work_gname, work_gid } = res.data.message.data[0];
      this.workname = `${work_gid} - ${work_gname}`;
    },
    async increase_assets_number() {
      // console.log(this.rowData);
      const { access_token } = await this.access_token();
      const url = `${API}appendEquipmentRegistrationAmount/${this.rowData.e_gid}&${this.rowData.e_id}&${this.rowData.equipment_code}`;
      const data = {
        amount: this.increase_assets,
      };
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      // const res = await axios.put(url, data, head);

      this.keyinput = `${this.increase_assets}`;
      (this.show_b = true), (this.rows = []);

      this.generateNumber();
    },
    async get_log(value) {
      // console.log(value.e_number);
      const { access_token } = await this.access_token();
      const url = `${API}equipmentLogs?e_number=${value.e_number}&_sort=1`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      console.log(res);
      const tt = res.data.message.data.map((el) => {
        return {
          ...el,
          createdAt: ` ${parseInt(el.createdAt.split(" ")[0].split("-")[2])} ${
            this.month[parseInt(el.createdAt.split(" ")[0].split("-")[1])]
          } ${Number(el.createdAt.split(" ")[0].split("-")[0]) + 543}`,
          time: el.createdAt.split(" ")[1],
          work_gname: `${el.work_gid} - ${el.work_gname}`,
        };
      });

      // console.log(tt);
      this.rows_log = tt;
      this.total_log = res.data.message.total;
    },
    formatFn: function (value) {
      return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    },
    async cc_data(value) {
      this.image_p = "";
    },

    urltoFile(url, filename, mimeType) {
      return fetch(url)
        .then(function (res) {
          return res.arrayBuffer();
        })
        .then(function (buf) {
          return new File([buf], filename, { type: mimeType });
        });
    },
    // dataURLtoFileEdit(dataurl, filename) {
    //   var arr_1 = dataurl.split(','),
    //     mime = arr_1[0].match(/:(.*?);/)[1],
    //     bstr = atob(arr_1[1]),
    //     n = bstr.length,
    //     u8arr = new Uint8Array(n);
    //   while (n--) {
    //     u8arr[n] = bstr.charCodeAt(n);
    //   }

    //   return new File([u8arr], filename, { type: mime });
    // },

    async edit_data(edit_data) {
      console.log(edit_data);
      const { access_token } = await this.access_token();
      const url = `${API}equipmentDetail/${edit_data.e_number.replace("/", "_")}`;
      const data = new FormData();
      data.append("serial_number", edit_data.serial_number);
      data.append("place", edit_data.place);
      data.append("responsename", edit_data.responsename);
      data.append("e_status", edit_data.e_status);
      data.append("withdraw_person", edit_data.withdraw_person);
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      for (const value of data) {
        console.log(value);
      }
      const res = await axios.put(url, data, head);
      const { username } = await JSON.parse(localStorage.getItem("username_type"));
      const url_ReturnStatus = `${API}equipmentReturnStatus`;
      const data_ReturnStatus = {
        work_gid: this.workG.work_gid,
        return_date: "",
        e_number: edit_data.e_number,
        place: edit_data.place,
        responsename: edit_data.responsename,
        username: username,
      };

      console.log(data_ReturnStatus);
      const res_ReturnStatus = await axios.post(url_ReturnStatus, data_ReturnStatus, head);
      console.log(res_ReturnStatus);

      console.log(res);

      const url_log = `${API}equipmentLogs`;

      const data_log = {
        username: username,
        e_number: edit_data.e_number,
        place: edit_data.place,
        reponsename: edit_data.responsename,
      };
      const res_log_data = await axios.post(url_log, data_log, head);
      console.log(res_log_data);

      Swal.fire({
        position: "center",
        icon: "success",
        title: `แก้ไขทรัพย์สิน หมายเลข${edit_data.e_number}`,
        showConfirmButton: false,
        timer: 1500,
      });
      this.getdataTable();
    },
    async getdataTable() {
      this.show = true;
      const { access_token } = await this.access_token();
      const url = `${API}inventoryList?equipment_code=${this.rowData.equipment_code}&e_id=${this.rowData.e_id}&refkey=${this.rowData.refkey}&_page=1&_limit=100`;
      console.log(url);
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, header);
      console.log(res.data.message);
      console.log(this.pageLength);
      console.log(this.currentPage);
      const res_total = Math.ceil(Number(res.data.message.total) / 100);
      console.log(res_total);
      const data_ = res.data.message.data;
      const url_link = [];
      for (let i = 1; i <= res_total; i++) {
        url_link.push(
          `${API}inventoryList?equipment_code=${this.rowData.equipment_code}&e_id=${this.rowData.e_id}&refkey=${this.rowData.refkey}&_page=${i}&_limit=100`
        );
      }
      let res_data_ = [];
      for (let i in url_link) {
        res_data_ = [...res_data_, ...(await axios.get(url_link[i], header)).data.message.data];
      }
      console.log(res_data_);

      //const data_ = res.data.message.data

      //console.log(data_);

      if (data_.length > 0) {
        this.rows = res_data_; //[...this.rows,...data_]
        console.log(this.rows);
        // this.temp_rows = res.data.message.data;
        this.total = res.data.message.total;

        this.show_b = false;
        this.show = false;
      } else {
        this.generateNumber();
      }
    },
    // onFileChange(f) {
    //   const file = f.target.files[0];
    //   this.file_mImg = URL.createObjectURL(file);
    // },
    changeCell(vaule, column, row) {
      console.log(vaule, column, row);
      this.rows[row][column] = vaule;
      console.log(vaule);
    },
    async generateNumber() {
      this.show = true;
      // const ttt = {...this.rowData}
      // delete ttt.e_gid;
      // delete ttt.createdAt;
      // console.log(ttt);
      const { access_token } = await this.access_token();
      if (this.increase_assets > 0) {
        this.keyinput = ` ${this.increase_assets}`;
      }
      console.log(this.keyinput);
      const url = `${API}generateMaterialNumber?equipment_code=00-000-${this.ycode}&e_id=${this.rowData.e_id}&e_gid=${
        this.rowData.e_gid
      }&amount=${this.keyinput === "" ? this.keyinput : this.keyinput.toString().trim()}&refkey=${
        this.rowData.refkey
      }&_page=1&_limit=100`; // const datastan = {
      console.log(url);
      //   equipment_code: equipment_code,
      //   e_id: e_id,
      //   amount: this.keyinput,
      // };
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, header);
      console.log(res.data.message.length);
      // const { data, qrcode } = res.data.message;
      // console.log(data);
      // const tables = {...res.data.message}
      // console.log(tables)
      // // const abc = [
      // //   {...res.data.message}
      // // ]
      // // console.log(abc)
      const response = res.data.message;
      const gen = response.data.map((res, index) => {
        return {
          e_number: "AUTO",
          serial_number: "-",
          place: "-",
          responsename: "-",
          e_status: "false",
          e_image: "",
          ...this.rowData,
        };
      });

      // this.qrcode = gen[0].qrcode,

      this.rows = gen;
      this.total = res.data.message.length;
      this.show = false;
    },

    async getdata() {
      this.show_getdata = true;
      Swal.fire({
        title: "กรุณารอสักครู่...",
        showCloseButton: false,
        showDenyButton: false,
        showCancelButton: false,
        showConfirmButton: false,
      });

      var flagExist = "";
      const xx = await this.rows.map(async (el) => {
        // console.log(el.serial_number);
        // console.log(el.place);
        // console.log(el.responsename);
        if (_.isEmpty(el.serial_number) || _.isEmpty(el.place) || _.isEmpty(el.responsename)) {
          // Swal.fire({
          //   position: 'center',
          //   icon: 'error',
          //   title: `กรุณากรอกข้อมูลให้ครบ`,
          //   showConfirmButton: false,
          //   timer: 1500,
          // });
          console.log("sssssssssssssssssssss");
          flagExist = "true";
          return "xxx";
        }
      });
      console.log(await xx);
      if (flagExist == "true") {
        console.log("rrrrrrrrrrr");
        Swal.fire({
          position: "center",
          icon: "error",
          title: `กรุณากรอกข้อมูลให้ครบ`,
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      }
      console.log("cccccccccccccccccccccccccccccccccccccc");

      const { access_token } = await this.access_token();
      // console.log(typeof this.increase_assets);
      const header_get = {
        headers: {
          Authorization: await access_token,
        },
      };

      if (this.increase_assets > 0) {
        const url_insert = `${API}appendEquipmentRegistrationAmount/${this.rowData.e_gid}&${this.rowData.e_id}&${this.rowData.equipment_code}&${this.rowData.refkey}`;
        console.log(url_insert);
        const data__insert = {
          amount: this.increase_assets,
        };
        const res = await axios.put(url_insert, data__insert, header_get);
        console.log(res);
      }

      const url_get = `${API}generateMaterialNumber?equipment_code=00-000-${this.ycode}&e_id=${this.rowData.e_id}&e_gid=${this.rowData.e_gid}&amount=${this.keyinput}&_page=1&_limit=100`;
      ``;
      // console.log(url_get);

      let res_getauto_new_number = await axios.get(url_get, header_get);

      const url = `${API}generateMaterialNumber`;
      const url1 = `${API}regisReceiveAccess`;
      const data = {
        e_gid: this.rowData.e_gid,
        equipment_code: `00-000-${this.ycode}`,
        e_id: this.rowData.e_id,
        amount: this.keyinput.toString(),
      };
      console.log(data);
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };

      const res1 = await axios.post(url, data, header);
      console.log(res1);
      const res = await Promise.all(
        this?.rows?.map(
          async (resa, index) =>
            await axios.post(
              url1,
              {
                equipment_code: resa.equipment_code,
                e_id: resa.e_id,
                e_number: res_getauto_new_number.data.message.data[index],
                serial_number: resa.serial_number,
                e_name: resa.e_name,
                place: resa.place,
                responsename: resa.responsename,
                e_status: resa.e_status,
                refkey: this.rowData.refkey,
              },
              header
            )
        )
      );

      const { username } = await JSON.parse(localStorage.getItem("username_type"));
      const url_log = `${API}equipmentLogs`;
      const los_log = await Promise.all(
        this?.rows?.map(
          async (resa, index) =>
            await axios.post(
              url_log,
              {
                username: username,
                e_number: res_getauto_new_number.data.message.data[index],
                place: resa.place,
                reponsename: resa.responsename,
              },
              header
            )
        )
      );

      const url_ReturnStatus = `${API}equipmentReturnStatus`;
      const res_ReturnStatus = await Promise.all(
        this?.rows?.map(
          async (resa, index) =>
            await axios.post(
              url_ReturnStatus,
              {
                work_gid: this.rowData.work_gid,
                return_date: "",
                e_number: res_getauto_new_number.data.message.data[index],
                place: resa.place,
                responsename: resa.responsename,
                username: username,
              },
              header
            )
        )
      );

      console.log(res_ReturnStatus);
      // console.log(los_log);

      // console.log(res);
      let run_num = 0;
      let status_on = null;
      res.forEach((element) => {
        run_num++;
        status_on = element;
      });
      if (status_on.data.status == 200) {
        Swal.fire({
          position: "top-center",
          icon: "success",
          title: `บันทึกสำเร็จ ${run_num} รายการ`,
          showConfirmButton: false,
          timer: 1500,
        });
        this.getdataTable();
      } else {
        // console.log(res);
        // Swal.fire({
        //   position: 'top-center',
        //   icon: 'error',
        //   title: `บันทึกไม่สำเร็จ ${run_num} รายการ `,
        //   showConfirmButton: false,
        //   timer: 1500,
        // });
      }
      Swal.close();
      this.show_getdata = false;
      // this.rows.forEach(async(postdata) => {
      //   console.log(postdata)
      //   const url = `${API}regisReceiveAccess`;
      // const data = ;

      //   const header = {
      //     headers: {
      //       Authorization: await access_token,
      //     },
      //   };

      //   const res = await axios.post(url, data, header);
      //   console.log(res);
      // });
    },
    async access_token() {
      return (
        await axios.post(`${API}permit`, "", {
          headers: {
            Authorization: localStorage.getItem("storedData") && JSON.parse(localStorage.getItem("storedData")),
          },
        })
      ).data.message;
    },
    running() {
      // console.log(this.number);
      // console.log('test');
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
.delect_icon {
  cursor: pointer;
  position: absolute;
  margin-left: 67%;
  margin-top: 10%;
}
.delect_img {
  backface-visibility: hidden;
}
</style>
